<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine which of the following statements are true. Please check all that apply.
      </p>

      <div v-for="option in options" :key="option.value">
        <v-checkbox
          v-model="inputs.input1"
          class="d-inline-block pl-10"
          :value="option.value"
          :dense="true"
          hide-details="true"
          :ripple="false"
          :label="option.text"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'Question275',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
      },
      options: [
        {
          text: 'A child builds a castle out of a pile of lego blocks. The entropy of the lego pieces increases.',
          value: 'a',
        },
        {
          text: 'A layer of red jelly beans is placed in a jar on top of a white layer of jelly beans. The jar is then shaken. The entropy of the jelly beans decreases.',
          value: 'b',
        },
        {
          text: 'An empty glass is dropped on the floor and shatters into many pieces. The entropy of the glass increases.',
          value: 'c',
        },
        {
          text: 'In a campfire, a piece of wood burns to form gas and ashes. The entropy of the wood increases.',
          value: 'd',
        },
      ],
    };
  },
};
</script>
<style scoped>
.v-input {
  margin-top: 0;
}
</style>
